.plans{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0 2rem;
    margin-top: -4rem;
    position: relative;
}
.plans-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
.plan-categories{
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: center;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    padding: 1.5em;
    gap: 2rem;
    color: white;
    width: 15rem;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: var(--orange);
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.imgf{
    width: 1rem;
}
.plan:nth-child(2)>svg{
    fill: white;
}
.plan:nth-child(2)>button{color: orange;}

.seemore{
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 0.8rem;
}
.seemore>img{
    width: 0.8rem;
}
.category:hover{
    background: var(--planCard);
    cursor: pointer;
}
.plans-blur-1{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left: 0;
}
.plans-blur-2{
    width: 32rem;
    height: 23rem;
    right: 0;
    top: 10rem;
}
@media screen and (max-width: 780px){
    .plan-categories{
        flex-direction: column;
    }
    .plan-categories > :nth-child(2){
        transform: none;
    }
}
